import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {SearchBar, SearchResults} from 'components/search'
import {graphql} from 'gatsby'
import MediaQuery from 'react-responsive'
import { TitleBanner } from "../components/banners/titleBanner";



const doSearch = (query, dispatch) => {
  if(!query || query===''){
    return;
  }
  const search_endpoint = `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/gatsby/v1/search`
  const with_query = `${search_endpoint}?s=${query}`

  dispatch({type:'startsearch', query:query})
  
  fetch(with_query)
    .then(response=>response.json())
    .then( (response)=>{
      dispatch({
        type:'search',
        results:response.map(r=>({wordpress_id:r.ID, type:r.post_type}))
      })
    })
    .catch((error)=>{
      dispatch({type:'search', results:[], isSearching:false })
      return [];
    })

}


const setQuery = (state, action )=> {
  switch(action.type){
  case 'startsearch':
  
    history.pushState(history.state,'search', `${window.location.pathname}?q=${action.query}`)
    return {
      ...state,
      query: action.query,
      results:[],
      isSearching: true
    }
  case 'search':


    return ({
      ...state,
      results: action.results,
      isSearching:false
    })
  default:
    throw new Error()
  }
}
const Search = (props) => {
  let searchQuery = '';
  if (props.location.search && props.location.search !== ''){
    searchQuery = props.location.search
      .slice(1)
      .split('&')
      .map(p=>p.split('='))
      .reduce((o,[k,v])=>({...o, [k]:decodeURIComponent(v)} ), {})
  }
  const [state, dispatch] = React.useReducer(
    setQuery,
    {
      isSearching: false,
      results: [],
      query:searchQuery.hasOwnProperty('q')?searchQuery.q:''
    });
  React.useEffect(()=>{
    
    doSearch(state.query, dispatch)
  }, [state.query])

  return (
    <Layout
      articleId={`${props.data.wordpressPage.type}-${props.data.wordpressPage.wordpress_id}`}
      articleClassName={`${props.data.wordpressPage.classes}`}
      noSearchButton={true}
      >
      <TitleBanner title={props.data.wordpressPage.title} />
      <div className="container mx-auto" style={{maxWidth:930}}>
        <SearchBar
          defaultValue={searchQuery.q}
          onClickHandler={(query)=>{
            dispatch({type:'startsearch', query:query})
          }}
        />
        
        <div className="inline-block mx-auto w-full">
          <div className="mt-10 mx-auto lg:m-10 w-full">
            {state.isSearching?
             <p>Please wait...</p>
             :
             <SearchResults
               results={state.results}
               query={searchQuery.q}
             />
            }
          </div>
        </div>
        

      </div>
    </Layout>
  )
}

export default Search



export const query = graphql`
  query SearchPage($id: String!){
wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
  }

    file(relativePath: { eq: "test/thumb.jpg" }) {
      ...ContentCardImages
    }
  }
`
